import React from "react";
import Logo from "../images/Logo_Ib_Entreprend.jpg"
export const Navigation = (props) => {
    return (
        <nav id="menu" className="navbar navbar-default navbar-fixed-top">
            <div className="container">
                <div className="navbar-header">
                    <button
                        type="button"
                        className="navbar-toggle collapsed"
                        data-toggle="collapse"
                        data-target="#bs-example-navbar-collapse-1"
                    >
                        {" "}
                        <span className="sr-only">Toggle navigation</span>{" "}
                        <span className="icon-bar"></span>{" "}
                        <span className="icon-bar"></span>{" "}
                        <span className="icon-bar"></span>{" "}
                    </button>
                    <a className="navbar-brand page-scroll" href="#page-top">


                        <img src={Logo} style={{ width: "50px", height: "50px" }} />

                    </a>{" "}
                    <div style={{ marginBottom: "30px" }}></div>
                </div>

                <div
                    className="collapse navbar-collapse"
                    id="bs-example-navbar-collapse-1"
                >
                    <ul className="nav navbar-nav navbar-right">
                        <li>
                            <a href="#features" className="page-scroll">
                                Home
                            </a>
                        </li>
                        <li>
                            <a href="https://blog.ibentreprend.com/affiliate/" className="page-scroll">
                                Affiliate
                            </a>
                        </li>
                        <li>
                            <a href="https://books.ibentreprend.com" className="page-scroll">
                                Ebooks
                            </a>
                        </li>
                        <li>
                            <a href="https://blog.ibentreprend.com/product/wordpress-website-creation-coaching-for-beginners-learn-to-build-blogs-e-commerce-stores-and-landing-pages/" className="page-scroll">
                                Coaching
                            </a>
                        </li>
                        <li>
                            <a href="https://busyengi.com" className="page-scroll">
                                Consulting
                            </a>
                        </li>
                        {/* <li>
                            <a href="https://blog.ibentreprend.com/e-commerce/" className="page-scroll">
                                E-commerce
                            </a>
                        </li>
                        <li>
                            <a href="https://blog.ibentreprend.com/music/" className="page-scroll">
                                Music
                            </a>
                        </li> */}

                        <li>
                            <a href="https://books.ibentreprend.com/contact/" className="page-scroll">
                                Contact
                            </a>
                        </li>


                    </ul>
                </div>
            </div>
        </nav>
    );
};